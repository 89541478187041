import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { secondary } from '../elements'

const PrivacyPage = () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy</title>
      <meta name="description" content="Privacy Policy" />
    </Helmet>

    <Container>
      <PolicyBox>
        <PageTitle>Privacy Policy</PageTitle>

        <h6>Last updated April 04, 2019</h6>

        <p>
          Thank you for choosing to be part of our community at D&amp;D
          Transportation Services, Inc. (“<strong>Company</strong>”, “
          <strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>
          ”). We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about our
          policy, or our practices with regards to your personal information,
          please contact us at info@ddtsi.com.
        </p>
        <p>
          When you visit our website https://ddtsi.com, and use our services,
          you trust us with your personal information. We take your privacy very
          seriously. In this privacy notice, we describe our privacy policy. We
          seek to explain to you in the clearest way possible what information
          we collect, how we use it and what rights you have in relation to it.
          We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy policy that you do
          not agree with, please discontinue use of our Sites and our services.
        </p>
        <p>
          This privacy policy applies to all information collected through our
          website (such as https://ddtsi.com), and/or any related services,
          sales, marketing or events (we refer to them collectively in this
          privacy policy as the "<strong>Sites</strong>").
        </p>
        <p>
          <strong>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </strong>
        </p>

        <h5>TABLE OF CONTENTS</h5>
        <p>
          <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
        </p>
        <p>
          <a href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#infoshare">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </a>{' '}
        </p>
        <p>
          <a href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
        </p>
        <p>
          <a href="#cookies">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </p>
        <p>
          <a href="#googlemaps">6. DO WE USE GOOGLE MAPS?</a>
        </p>
        <p>
          <a href="#sociallogins">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </p>
        <p>
          <a href="#inforetain">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#infosafe">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </p>
        <p>
          <a href="#infominors">10. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </p>
        <p>
          <a href="#privacyrights">11. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </p>
        <p>
          <a href="#DNT">12. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </p>
        <p>
          <a href="#caresidents">
            13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </p>
        <p>
          <a href="#policyupdates">14. DO WE MAKE UPDATES TO THIS POLICY?</a>
        </p>
        <p>
          <a href="#contact">15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a>
        </p>
        <p id="infocollect">
          <h5>1. WHAT INFORMATION DO WE COLLECT?</h5>
        </p>
        <p>
          <h6>Personal information you disclose to us</h6>
        </p>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We collect personal information that you provide to us such as name,
            address, contact information, passwords and security data, payment
            information, and social media login data
          </em>
          .
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when registering at the Sites expressing an interest in obtaining
          information about us or our products and services, when participating
          in activities on the Sites or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Sites, the choices you make and the
          products and features you use. The personal information we collect can
          include the following:
        </p>
        <p>
          <strong>Name and Contact Data.</strong> We collect your first and last
          name, email address, postal address, phone number, and other similar
          contact data.
        </p>
        <p>
          <strong>Credentials.</strong> We collect passwords, password hints,
          and similar security information used for authentication and account
          access.
        </p>
        <p>
          <strong>Payment Data.</strong> We collect data necessary to process
          your payment if you make purchases, such as your payment instrument
          number (such as a credit card number), and the security code
          associated with your payment instrument. All payment data is stored by
          our payment processor and you should review its privacy policies and
          contact the payment processor directly to respond to your questions.
        </p>
        <p>
          <strong>Social Media Login Data.</strong> We provide you with the
          option to register using social media account details, like your
          Facebook, Twitter or other social media account. If you choose to
          register in this way, we will collect the Information described in the
          section called "
          <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>"
          below.
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p>
          <h6>Information automatically collected</h6>
        </p>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            Some information – such as IP address and/or browser and device
            characteristics – is collected automatically when you visit our
            Sites.
          </em>
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Sites. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Sites and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Sites, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <h6>Information collected from other sources</h6>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We may collect limited data from public databases, marketing
            partners, social media platforms, and other outside sources.
          </em>
        </p>
        <p>
          We may obtain information about you from other sources, such as public
          databases, joint marketing partners, social media platforms (such as
          Facebook), as well as from other third parties. Examples of the
          information we receive from other sources include: social media
          profile information (your name, gender, birthday, email, current city,
          state and country, user identification numbers for your contacts,
          profile picture URL and any other information that you choose to make
          public); marketing leads and search results and links, including paid
          listings (such as sponsored links).
        </p>

        <h5 id="infouse">2. HOW DO WE USE YOUR INFORMATION?</h5>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </em>
        </p>
        <p>
          We use personal information collected via our Sites for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <strong>To facilitate account creation and logon process. </strong>
            If you choose to link your account with us to a third party account
            *(such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process. See the section below headed "
            <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" for
            further information.
          </li>
          <li>
            <strong>
              To send you marketing and promotional communications.{' '}
            </strong>
            We and/or our third party marketing partners may use the personal
            information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. You can opt-out of our
            marketing emails at any time (see the "
            <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS</a>" below).
          </li>
          <li>
            <strong>To send administrative information to you.</strong> We may
            use your personal information to send you product, service and new
            feature information and/or information about changes to our terms,
            conditions, and policies.
          </li>
          <li>
            <strong>Fulfill and manage your orders.</strong> We may use your
            information to fulfill and manage your orders, payments, returns,
            and exchanges made through the Sites.
          </li>
          <li>
            <strong>Request Feedback.</strong> We may use your information to
            request feedback and to contact you about your use of our Sites.
          </li>
          <li>
            <strong>To protect our Sites.</strong> We may use your information
            as part of our efforts to keep our Sites safe and secure (for
            example, for fraud monitoring and prevention).
          </li>
          <li>
            <strong>To enable user-to-user communications.</strong> We may use
            your information in order to enable user-to-user communications with
            each user's consent.
          </li>
          <li>
            <strong>To enforce our terms, conditions and policies.</strong>
          </li>
          <li>
            <strong> To respond to legal requests and prevent harm. </strong>
            If we receive a subpoena or other legal request, we may need to
            inspect the data we hold to determine how to respond.
          </li>
          <li>
            <strong>For other Business Purposes.</strong> We may use your
            information for other Business Purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Sites,
            products, services, marketing and your experience.
          </li>
        </ul>

        <h5 id="infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We only share information with your consent, to comply with laws, to
            protect your rights, or to fulfill business obligations.
          </em>
        </p>

        <p>We may process or share data based on the following legal basis:</p>
        <ul>
          <li>
            <strong>Consent:</strong> We may process your data if you have given
            us specific consent to use your personal information in a specific
            purpose.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> We may process your data when
            it is reasonably necessary to achieve our legitimate business
            interests.
          </li>
          <li>
            <strong>Performance of a Contract:</strong> Where we have entered
            into a contract with you, we may process your personal information
            to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information
            where we are legally required to do so in order to comply with
            applicable law, governmental requests, a judicial proceeding, court
            order, or legal process, such as in response to a court order or a
            subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </li>
          <li>
            <strong>Vital Interests:</strong> We may disclose your information
            where we believe it is necessary to investigate, prevent, or take
            action regarding potential violations of our policies, suspected
            fraud, situations involving potential threats to the safety of any
            person and illegal activities, or as evidence in litigation in which
            we are involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <ul>
          <li>
            <strong>
              Vendors, Consultants and Other Third-Party Service Providers.{' '}
            </strong>
            We may share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Sites,
            which will enable them to collect data about how you interact with
            the Sites over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes.
          </li>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Third-Party Advertisers.</strong> We may use third-party
            advertising companies to serve ads when you visit the Sites. These
            companies may use information about your visits to our Website(s)
            and other websites that are contained in web cookies and other
            tracking technologies in order to provide advertisements about goods
            and services of interest to you.
          </li>
          <li>
            <strong>Business Partners.</strong> We may share your information
            with our business partners to offer you certain products, services
            or promotions.
          </li>
        </ul>

        <h5 id="whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>We only share information with the following third parties.</em>
        </p>

        <p>
          We only share and disclose your information with the following third
          parties. We have categorized each party so that you may be easily
          understand the purpose of our data collection and processing
          practices. If we have processed your data based on your consent and
          you wish to revoke your consent, please contact us.
        </p>

        <ul>
          <li>
            <strong>
              Allow Users to Connect to their Third-Party Accounts
            </strong>
            , Facebook account, Google account and Instagram account
          </li>
        </ul>
        <ul>
          <li>
            <strong>Communicate and Chat with Users </strong>
            Zendesk Chat
          </li>
        </ul>

        <ul>
          <li>
            <strong>Content Optimization </strong>
            Google Site Search and YouTube video embed
          </li>
        </ul>

        <ul>
          <li>
            <strong>Invoice and Billing </strong>
            Stripe
          </li>
        </ul>

        <ul>
          <li>
            <strong>Social Media Sharing and Advertising </strong>
            Facebook social plugins and Twitter social plugins
          </li>
        </ul>
        <ul>
          <li>
            <strong>User Account Registration and Authentication </strong>
            Facebook Login, Google Sign-In and Twitch Authentication
          </li>
        </ul>
        <ul>
          <li>
            <strong>User Commenting and Forums </strong>
            Disqus
          </li>
        </ul>
        <ul>
          <li>
            <strong>Web and Mobile Analytics </strong>
            Google Analytics
          </li>
        </ul>
        <ul>
          <li>
            <strong>Website Hosting </strong>
            Shopify
          </li>
        </ul>

        <h5 id="cookies">
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We may use cookies and other tracking technologies to collect and
            store your information.
          </em>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy.
        </p>

        <h5 id="googlemaps">6. DO WE USE GOOGLE MAPS?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            Yes, we use Google Maps for the purpose of providing better service.
          </em>
        </p>
        <p>
          This website, mobile application, or Facebook application uses Google
          Maps APIs. You may find the Google Maps APIs Terms of Service{' '}
          <a href="https://developers.google.com/maps/terms" target="_blank">
            here
          </a>
          . To better understand Google’s Privacy Policy, please refer to this{' '}
          <a href="https://policies.google.com/privacy" target="_blank">
            link
          </a>
          .
        </p>
        <p>
          By using our Maps API Implementation, you agree to be bound by
          Google’s Terms of Service. You agree to allow us to obtain or cache
          your location. You may revoke your consent at anytime. We use
          information about location in conjunction with data from other data
          providers.
        </p>

        <h5 id="sociallogins">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            If you choose to register or log in to our websites using a social
            media account, we may have access to certain information about you.
          </em>
        </p>
        <p>
          Our Sites offer you the ability to register and login using your third
          party social media account details (like your Facebook or Twitter
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          Information we receive may vary depending on the social media provider
          concerned, but will often include your name, e-mail address, friends
          list, profile picture as well as other information you choose to make
          public.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy policy or that are otherwise made clear to
          you on the Sites. Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third
          party social media provider. We recommend that you review their
          privacy policy to understand how they collect, use and share your
          personal information, and how you can set your privacy preferences on
          their sites and apps.
        </p>
        <h5 id="inforetain">8. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy policy unless otherwise required
            by law.
          </em>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize it, or, if
          this is not possible (for example, because your personal information
          has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible.
        </p>

        <h5 id="infosafe">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </em>
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your personal information, transmission of personal
          information to and from our Sites is at your own risk. You should only
          access the services within a secure environment.
        </p>

        <h5 id="infominors">10. DO WE COLLECT INFORMATION FROM MINORS?</h5>

        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            We do not knowingly collect data from or market to children under 18
            years of age.
          </em>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Sites, you represent that you are at least
          18 or that you are the parent or guardian of such a minor and consent
          to such minor dependent’s use of the Sites. If we learn that personal
          information from users less than 18 years of age has been collected,
          we will deactivate the account and take reasonable measures to
          promptly delete such data from our records. If you become aware of any
          data we have collected from children under age 18, please contact us
          at info@ddtsi.com.
        </p>
        <h5 id="privacyrights">11. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            You may review, change, or terminate your account at any time.
          </em>{' '}
        </p>
        <p>
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:{' '}
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <p>■ Contact us using the contact information provided.</p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>
        <p>
          <strong>
            <u>Cookies and similar technologies:</u>{' '}
          </strong>
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Sites. To
          opt-out of interest-based advertising by advertisers on our Sites
          visit{' '}
          <a href="http://www.aboutads.info/choices/" target="_blank">
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Opting out of email marketing:</u>{' '}
          </strong>
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us using the details provided below. You will then be
          removed from the marketing email list – however, we will still need to
          send you service-related emails that are necessary for the
          administration and use of your account. To otherwise opt-out, you may:
        </p>
        <p>■ Access your account settings and update preferences.</p>

        <p id="DNT">
          <strong>12. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </p>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about
          that practice in a revised version of this Privacy Policy.
        </p>

        <p id="caresidents">
          <strong>
            13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </strong>
        </p>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </em>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Sites, you have the right to request
          removal of unwanted data that you publicly post on the Sites. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Sites, but
          please be aware that the data may not be completely or comprehensively
          removed from our systems.
        </p>
        <p id="policyupdates">
          <strong>14. DO WE MAKE UPDATES TO THIS POLICY?</strong>
        </p>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <em>
            Yes, we will update this policy as necessary to stay compliant with
            relevant laws.
          </em>
        </p>
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </p>
        <p id="contact">
          <strong>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong>
        </p>
        <p>
          If you have questions or comments about this policy, you may email us
          at info@ddtsi.com or by post to:
        </p>
        <p>
          <strong>D&amp;D Transportation Services, Inc.</strong>
          <br />
          <strong>1735 Main Street </strong>
          <br />
          <strong>Gooding, ID 83330</strong>
          <br />
          <strong>United States </strong>
        </p>
      </PolicyBox>
    </Container>
  </Layout>
)

export default PrivacyPage

export const PolicyBox = styled.div`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  position: relative;
  margin: 1rem auto;
  padding: 2rem;
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  a {
    text-decoration: none;
    color: ${secondary};
  }
`
